import React, { useState } from "react";
import classes from "./Footer.module.css";
// Components
import Modal from "../../UI/Modal/Modal";
import CGU from "../../Information/CGU/CGU";
import PDC from "../../Information/PDC/PDC";

const Footer = () => {
  const [showCGU, setShowCGU] = useState(false);
  const [showPDC, setShowPDC] = useState(false);

  return (
    <div className={classes.Footer}>
      <Modal show={showCGU} clicked={() => setShowCGU(false)}>
        <CGU />
      </Modal>
      <Modal show={showPDC} clicked={() => setShowPDC(false)}>
        <PDC />
      </Modal>
      <div className={classes.LegalLinks}>
        <p onClick={() => setShowCGU(true)}>
          Conditions Générales d’Utilisation
        </p>
        <p onClick={() => setShowPDC(true)}>Politique de confidentialité</p>
      </div>
      <div className={classes.Copyrights}>
        <div className={classes.ImmobiliereLogo}>
          <img
            style={{ width: "100%" }}
            alt="hand"
            src={require(`../../../assets/homePage/LOGORDL.png`)}
          />
        </div>
        <p>
          <span style={{ marginRight: ".5rem" }}>Corehab</span> 2021, tous
          droits réservés <span style={{ marginLeft: ".5rem" }}>1001RUES</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
