import React from "react";
import "./App.css";
// Pages / Containers
import Login from "./containers/Login/Login";
import Home from "./containers/Home/Home";
import ProfileSetup from "./containers/ProfileSetup/ProfileSetup";
import Travaux from "./containers/Travaux/Travaux";
import ProfilePage from "./containers/ProfilePage/ProfilePage";
import Resultats from "./containers/Resultats/Resultats";
// Blog
// import PageOne from "./containers/Blog/BlogPages/PageOne";
// Routing
import { BrowserRouter as Router, Route } from "react-router-dom";
import PrivateRoute from "./config/PrivateRoute";
// Contexts
import { AuthProvider } from "./contexts/AuthContext";
import ProfileSetupContextProvider from "./contexts/ProfileSetupContext";
import ContactContextProvider from "./contexts/ContactContext";
import LoginContextProvider from "./contexts/LoginContext";
import TravauxContextProvider from "./contexts/TravauxContext";
import ResultatsContextProvider from "./contexts/ResultatsContext";

function App() {
  return (
    <AuthProvider>
      <Router>
        <ContactContextProvider>
          <LoginContextProvider>
            <ProfileSetupContextProvider>
              <TravauxContextProvider>
                <ResultatsContextProvider>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/login" component={Login} />
                  <PrivateRoute
                    exact
                    path="/profile-setup"
                    component={ProfileSetup}
                  />
                  {/* <PrivateRoute
                    exact
                    path="/my-profile"
                    component={ProfilePage}
                  /> */}
                  <PrivateRoute exact path="/travaux" component={Travaux} />
                  <PrivateRoute exact path="/resultats" component={Resultats} />
                </ResultatsContextProvider>
              </TravauxContextProvider>
            </ProfileSetupContextProvider>
          </LoginContextProvider>
        </ContactContextProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;
