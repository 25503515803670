import React from "react";

export const travauxCardsData = [
  {
    id: "c1",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Le nom",
    titleWeak: "du parc",
    description:
      "Votre nouveau parc mérite un nom qui le caractérisera, un nom en relation avec votre quartier, un nom qui rend hommage, un nom qui vous évoque quelque chose et qui définit pour les années à venir le caractère de ce nouveau parc.",
    thumbnail: "c1_default.svg",
    imgSrc: "c1_default.svg",
    plan: "Carte_Limite-du-parc.svg",
    tags: "Donner votre avis !",
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Seriez-vous prêt à vous impliquer dans la création ou l’entretien d’un jardin partagé ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Parmi les choix proposés, lequel à votre préférence?",
        options: [
          {
            label: "Parc Rouget-de-Lisle",
            value: "q1rep1",
          },
          {
            label: "Parc Jean-Moulin",
            value: "q1rep2",
          },
          {
            label: "Parc Lucie-Aubrac",
            value: "q1rep3",
          },
          {
            label: "Parc de La Marseillaise",
            value: "q1rep4",
          },
          {
            label: "Parc de la Renaissance",
            value: "q1rep5",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "c2",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Des agrès",
    titleWeak: "sportifs",
    description:
      "Du mobilier sportif est prévu dans votre nouveau parc. Nous souhaitons que celui-ci soit au plus proche de vos pratiques sportives. Pour cela, nous vous demandons de nous faire part de vos attentes.",
    thumbnail: "c2_default.svg",
    imgSrc: "c2_default.svg",
    plan: "Carte_Limite-du-parc.svg",
    tags: "Donner votre avis !",
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Seriez-vous prêt à vous impliquer dans la création ou l’entretien d’un jardin partagé ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Quel type de mobiliers sportifs correspond le plus à vos attentes.",
        options: [
          {
            label: "Fitness et cardio (Vélo, vélo elliptique, rameur, etc).",
            value: "q1rep1",
          },
          {
            label:
              "Musculation (Barre, musculation jambe, biceps, abdos, etc).",
            value: "q1rep2",
          },
          {
            label: "Un peu des deux.",
            value: "q1rep3",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },

  {
    id: "c3",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Les mobiliers",
    titleWeak: "dans le parc",
    description:
      "Choisir son mobilier n’est jamais anodin, il exprime quelque chose de vous-même tout en répondant à certains besoins ! Puisque vous le faites pour agrémenter votre intérieur, faites en de même pour votre futur parc !",
    thumbnail: "c3_default.svg",
    imgSrc: "c3_default.svg",
    plan: "Carte_Limite-du-parc.svg",
    tags: "Donner votre avis !",
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Des mobiliers sont prévus dans votre futur parc, parmi ces usages lesquels préférez-vous ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "checkbox",
        title:
          "Des mobiliers sont prévus dans votre futur parc, parmi ces usages lesquels préférez-vous ?",
        options: [
          {
            label: "Pour se détendre (transat, bancs, chaises individuelles)",
            value: "q1rep1",
          },
          {
            label: "Pour se rencontrer (tables de pique-nique, etc)",
            value: "q1rep2",
          },
          {
            label:
              "Pour observer la nature (hôtel à insectes, nichoirs à oiseaux, hôtel à hirondelles)",
            value: "q1rep3",
          },
          {
            label:
              "Pour s’informer (panneaux explicatifs sur le choix des végétaux, la gestion de la ressource en eau)",
            value: "q1rep4",
          },
          {
            label:
              "Pour partager (outils de réparation de vélo, boîte à livres)",
            value: "q1rep5",
          },
          // {
          //   label: "Proposer votre idée de mobilier",
          //   value: "q1rep100",
          // },
        ],
        maxChoices: 3,
        value: {
          q1rep1: false,
          q1rep2: false,
          q1rep3: false,
          q1rep4: false,
          q1rep5: false,
        },
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "c4",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Les usages de l’écrin",
    titleWeak: "de la phase 2",
    description:
      "L’écrin est un espace du parc qui sera réalisé en phase 2 mais son usage est pour le moment indéterminé. Pour cela nous avons besoin de vous pour nous aider à définir cet écrin.",
    thumbnail: "c4_default.svg",
    imgSrc: "c4_default.svg",
    plan: "Carte_L'ecrin.svg",
    tags: "Donner votre avis !",
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Des mobiliers sont prévus dans votre futur parc, parmi ces usages lesquels préférez-vous ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Parmi ces choix, quel usage conviendrait le mieux pour cet espace ?",
        options: [
          {
            label: "Un espace de repos au calme pour les adultes",
            value: "q1rep1",
          },
          {
            label: "Un espace pour jouer à la pétanque",
            value: "q1rep2",
          },
          {
            label: "Un espace végétalisé et fleuri",
            value: "q1rep3",
          },
          {
            label: "J’ai une autre idée",
            value: "q1rep100",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "c5",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Les usages de la promenade Edmond Michelet",
    titleWeak: "",
    description:
      "La promenade Edmond Michelet est le chemin principal qui traverse le parc. Sur cette promenade, des plateaux seront aménagés ponctuellement en salon pour accueillir des activités spécifiques. Nous souhaitons vous inclure dans la conception de ces salons et faire ressortir vos besoins prioritaires.",
    thumbnail: "c5_default.svg",
    imgSrc: "c5_default.svg",
    plan: "Carte_Promenade.svg",
    tags: "Donner votre avis !",
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Des mobiliers sont prévus dans votre futur parc, parmi ces usages lesquels préférez-vous ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "checkbox",
        title:
          "Parmi ces choix, priorisez les usages qui vous conviennent le mieux : ",
        options: [
          {
            label:
              "Des espaces de contemplations (transats, bancs, chaises individuelles)",
            value: "q1rep1",
          },
          {
            label: "Des espaces de jeux d’échecs et de jeux de dames",
            value: "q1rep2",
          },
          {
            label: "Des espaces d’activités sportifs (agrès sportifs)",
            value: "q1rep3",
          },
          {
            label: "Des espaces d’exposition",
            value: "q1rep4",
          },
          {
            label:
              "Des espaces libres pour la pratique de sport en groupe (Zumba, gym, yoga, tango...)",
            value: "q1rep5",
          },
          // {
          //   label: "J’ai une autre idée",
          //   value: "q1rep100",
          // },
        ],
        maxChoices: 2,
        value: {
          q1rep1: false,
          q1rep2: false,
          q1rep3: false,
          q1rep4: false,
          q1rep5: false,
        },
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "c6",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "La grande aire de jeu",
    titleWeak: "",
    description:
      "Une grande aire de jeux sera aménagée dans votre parc. Cette aire de jeu inclusive est destinée aux plus grands et sera réalisée dans la phase 3 des travaux et sera adaptée à tous les enfants. La question du type de jeu se pose aux concepteurs du projet et pour cela ils ont besoin de votre aide afin de les éclairer sur vos réels besoins.",
    thumbnail: "c6_default.svg",
    imgSrc: "c6_default.svg",
    plan: "Carte_Grande-aire-de-jeu.svg",
    tags: "Donner votre avis !",
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Des mobiliers sont prévus dans votre futur parc, parmi ces usages lesquels préférez-vous ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Quel type de jeu voyez-vous sur cette grande aire de jeu ?",
        options: [
          {
            label:
              "Des espaces de contemplations (transats, bancs, chaises individuelles)",
            value: "q1rep1",
          },
          {
            label: "Des espaces de jeux d’échecs et de jeux de dames",
            value: "q1rep2",
          },
          {
            label: "Des espaces d’activités sportifs (agrès sportifs)",
            value: "q1rep3",
          },
          {
            label: "Des espaces d’exposition",
            value: "q1rep4",
          },
          {
            label:
              "Des espaces libres pour la pratique de sport en groupe. (Zumba, gym, yoga, tango...)",
            value: "q1rep5",
          },
          {
            label: "J’ai une autre idée",
            value: "q1rep100",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "c7",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "La petite aire de jeu",
    titleWeak: "",
    description:
      "Une petite aire de jeux sera aménagée dans votre parc dans la phase 2 des travaux sur les espaces publics. Comme pour la grande aire de jeu prévue mais cette fois à plus petite échelle, la question du type de jeu se pose aux concepteurs du projet et pour cela ils ont besoin de votre aide afin de les éclairer sur vos réels besoins.",
    thumbnail: "c7_q1rep100.svg",
    imgSrc: "c7_q1rep100.svg",
    plan: "Carte_Petites-aires-de-jeu.svg",
    tags: "Donner votre avis !",
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Des mobiliers sont prévus dans votre futur parc, parmi ces usages lesquels préférez-vous ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title: "Quel type de jeu voyez-vous sur cette petite aire de jeu ?",
        options: [
          {
            label:
              "Des espaces de contemplations (transats, bancs, chaises individuelles)",
            value: "q1rep1",
          },
          {
            label: "Des espaces de jeux d’échecs et de jeux de dames",
            value: "q1rep2",
          },
          {
            label: "Des espaces d’activités sportifs (agrès sportifs)",
            value: "q1rep3",
          },
          {
            label: "Des espaces d’exposition",
            value: "q1rep4",
          },
          {
            label:
              "Des espaces libres pour la pratique de sport en groupe. (Zumba, gym, yoga, tango...)",
            value: "q1rep5",
          },
          {
            label: "J’ai une autre idée",
            value: "q1rep100",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "c8",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "L’espace central",
    titleWeak: "du parc",
    description:
      "Cet espace au centre du parc est également le poumon paysagé de celui-ci, qualifié de “prairie” par les concepteurs, cet espace qui se veut plus libre et plus ouvert pourra accueillir diverses activités. Doit-on laisser cet espace libre, doit-on y mettre des mobiliers et quel type de mobiliers, sont des questions que se posent les concepteurs et pour y répondre nous avons besoin de vos idées !",
    thumbnail: "c8_default.svg",
    imgSrc: "c8_default.svg",
    plan: "Carte_Espace-central.svg",
    tags: "Donner votre avis !",
    conditional: {
      id: "conditional",
      type: "conditional",
      title:
        "Des mobiliers sont prévus dans votre futur parc, parmi ces usages lesquels préférez-vous ?",
      options: {
        q1rep1: {
          label: "Oui",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Non",
          value: "q1rep2",
        },
      },
      isConditional: false,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Dans cet espace central aménagé en espace de nature, souhaitez-vous y trouver :",
        options: [
          {
            label: "Des tables de pique-nique.",
            value: "q1rep1",
          },
          {
            label: "Un espace de repos (Transats, etc...)",
            value: "q1rep2",
          },
          {
            label:
              "Ne pas installer de mobilier pour laisser la possibilité d’accueillir tout type d’activité.",
            value: "q1rep3",
          },
          {
            label: "J’ai une autre idée",
            value: "q1rep100",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "c9",
    type: "concertable",
    layout: "concertableLayoutOne",
    submitted: false,
    titleStrong: "Les sculptures",
    titleWeak: "du parc",
    description:
      "Les concepteurs ont eu l’idée d’installer des sculptures en bois regroupées dans la prairie.",
    thumbnail: "c9_q1rep1.svg",
    imgSrc: "c9_q1rep1.svg",
    plan: "Carte_Espace-central.svg",
    tags: "Donner votre avis !",
    conditional: {
      id: "conditional",
      type: "conditional",
      title: "Etes-vous favorable à cette idée ?",
      options: {
        q1rep1: {
          label: "Non",
          value: "q1rep1",
        },
        q1rep2: {
          label: "Oui",
          value: "q1rep2",
        },
      },
      isConditional: true,
      value: false,
    },
    questions: [
      {
        id: "q1",
        type: "radio",
        title:
          "Quel serait l’animal « Totem » à installer selon vous dans le parc ?",
        options: [
          {
            label: "Lapin",
            value: "q1rep1",
          },
          {
            label: "Grenouille",
            value: "q1rep2",
          },
          {
            label: "Tortue de terre",
            value: "q1rep3",
          },
          {
            label: "Renard",
            value: "q1rep4",
          },
          {
            label: "Vache",
            value: "q1rep5",
          },
        ],
        value: "",
      },
    ],
    comment: "",
    noticeOne: "* Images à titre indicatif.",
  },
  {
    id: "nc1",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "La protection",
    titleWeak: "du vivant",
    description:
      "Les concepteurs du parc vont porter une attention toute particulière à la protection du vivant, de la plus grande espèce (vous) à la plus minuscule. Pour cela, des zones spécifiques seront aménagées :",
    descriptionPlus: [
      "La création d’une zone de prairie sèche pour la mante religieuse, l’espèce protégée du site !",
      "L’aménagement d’abris pour nos amis ailés ou à quatre pattes: nichoirs oiseaux, hôtel à hirondelle...",
      "Le parc sera entretenu de manière différenciée tout le long de l'année, afin de laisser pousser les fleurs printanières, les herbes hautes assez longtemps dans la saison, pour garantir les l'habitat et la protection de la faune qui apprécie les hautes herbes pour vivre.",
    ],
    thumbnail: "nc1-thumb.png",
    tags: "S'informer",
    video: {
      url: "https://youtu.be/BWDtZPx5Tf4",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
    plan: "Carte_Limite-du-parc.svg",
  },
  {
    id: "nc2",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "L’éclairage",
    titleWeak: "du parc",
    description:
      "L’éclairage du parc doit pouvoir concilier la sécurité de ses usagers la nuit tombée mais également la tranquillité du vivant. Aussi, il doit y avoir de la lumière, mais pas partout et pas tout le temps. Comment les concepteurs organisent tout cela dans le parc ?",
    descriptionPlus: [
      "A la nuit tombée, afin de garantir la tranquillité de la faune et de la flore, les éclairages du parc sont limités au cheminement piéton qui le longe. Ainsi les animaux et insectes ne sont pas dérangés dans leur repos nocturne.",
      "Les niveaux d'éclairement des cheminements garantissent un confort d'usage qui sécurise la traversée des espaces, tout en étant respectueux de l'environnement.",
    ],
    thumbnail: "nc2-thumb.png",
    tags: "S'informer",
    video: {
      url: "https://youtu.be/8zXTNtOKsRQ",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
    plan: "Carte_Limite-du-parc.svg",
  },
  {
    id: "nc3",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "Une promenade,",
    titleWeak: "plusieurs usages!",
    description:
      "La promenade Edmond Michelet, c’est la promenade principale du parc. Sur cette promenade vous pourrez apprécier le paysage imaginé par les concepteurs mais aussi vous adonner à diverses activités. Ces activités, dont certaines sont soumises à la concertation avec les riverains, seront organisées tout le long de la promenade par des salons ponctuels, sortes d’espaces dédiés. Pour cela les concepteurs veilleront à :",
    descriptionPlus: [
      "Accueillir des usages aux rythmes variés sur la Promenade Edmond Michelet: une piste cyclable bi-directionnelle, séparée du généreux espace piéton par une bande plantée, côtoiera différents salons.",
      "Créer des salons aux caractères diversifiés, avec des activités qui vont de la plus calme à la plus sportive. Détente et tranquillité pour les espaces de repos, jeux et repos pour les espaces avec les tables de jeux de dames, et enfin esprit sportif et dynamique dans les salons avec agrès de sport.",
      "Planter cette promenade piétonne d'arbres tiges et offrir un parcours privilégié qui surplombe les espaces du parc et une vue incomparable sur les espaces verts.",
    ],
    thumbnail: "nc3-thumb.png",
    tags: "S'informer",
    video: {
      url: "https://youtu.be/dfuwwM27QCE",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
    plan: "Carte_Promenade.svg",
  },
  {
    id: "nc4",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "Le traitement des eaux",
    titleWeak: "dans le parc",
    description:
      "Pour assurer la perméabilité des sols et l’exfiltration des eaux, une zone d’infiltration importante sera aménagée dans le parc. Pour créer cette zone, les concepteurs devront :",
    descriptionPlus: [
      "Dédier une partie du parc à l'infiltration naturelle de l'eau de pluie dans le sol: Le parc récupère de manière aérienne dans des noues et des cannelés des eaux pluviales qui s'infiltrent dans la terre.",
      "Concevoir un système hydraulique avec un écoulement en gravitaire des eaux du ciel.",
      "Permettre par des gradins et des pentes douces de gérer les altimétries relatives aux niveau des eaux selon l'ampleur des épisodes des pluies.",
    ],
    thumbnail: "nc4-thumb.svg",
    tags: "S'informer",
    video: {
      url: "https://youtu.be/tgvawIy7-ic",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
    plan: "Carte_Limite-du-parc.svg",
  },
];
