import React, { useState, useContext } from "react";
import classes from "./ModalCreateReclamation.module.css";
// Firebase
import { AuthContext } from "../../../contexts/AuthContext";
// MUI
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import LinearProgress from "@material-ui/core/LinearProgress";
// Components
import StepOne from "./ReclamationContent/StepOne";
import StepTwo from "./ReclamationContent/StepTwo";
import StepThree from "./ReclamationContent/StepThree";
import StepFour from "./ReclamationContent/StepFour";
import StepFive from "./ReclamationContent/StepFive";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ModalCreateReclamation = ({ show, setShow, setReclamations }) => {
  const { currentUser } = useContext(AuthContext);
  const [step, setStep] = useState(0);
  const [tagOne, setTagOne] = useState("");
  const [tagTwo, setTagTwo] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [pictures, setPictures] = useState([
    { id: "imageInputOne", url: "" },
    { id: "imageInputTwo", url: "" },
    { id: "imageInputThree", url: "" },
  ]);

  const classesMUI = useStyles();

  let stepContent;
  switch (step) {
    case 0:
      stepContent = <StepOne setStep={setStep} setShow={setShow} />;
      break;
    case 1:
      stepContent = (
        <StepFour
          setStep={setStep}
          setShow={setShow}
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
          pictures={pictures}
          setPictures={setPictures}
        />
      );
      break;
    case 2:
      stepContent = (
        <StepFive
          currentUser={currentUser}
          setStep={setStep}
          setShow={setShow}
          tagOne={tagOne}
          tagTwo={tagTwo}
          title={title}
          description={description}
          pictures={pictures}
          setReclamations={setReclamations}
        />
      );
      break;
  }

  const modalProgress =
    step < 2 ? (
      <LinearProgress variant="determinate" value={(step + 1) * 33} />
    ) : (
      <p style={{ color: "#EE9834" }}>
        Après validation, votre dessin / idée ne sera plus modifiable.
      </p>
    );
  const modalTitle =
    step < 2 ? (
      <h3 className={classes.ModalTitle}>Votre dessin / idée pas à pas</h3>
    ) : (
      <h3 className={classes.ModalTitle}>
        Récapitulatif de votre dessin / idée
      </h3>
    );
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classesMUI.modal}
        open={show}
        onClose={() => setShow(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <div className={classes.ModalContent}>
            <div>{modalProgress}</div>
            <div>{modalTitle}</div>
            {stepContent}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalCreateReclamation;
