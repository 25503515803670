import React, { useContext } from "react";
import classes from "./QuestionOne.module.css";
// MUI COMPONENTS
// Material Components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
// CONTEXTS
import { ProfileSetupContext } from "../../../contexts/ProfileSetupContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    textAlign: "left",
    fontSize: "1rem",
  },
}));

const DropDown = (props) => {
  const matClasses = useStyles();

  const quartiers = [
    "Quartier Rouget-de-Lisle",
    "Quartier Noailles",
    "Quartier Béthemont / La Bidonnière",
    "Quartier La Coudraie",
    "Quartier Abbaye",
    "Quartier Beauregard / Racine",
    "Quartier Beauregard / Corneille",
    "Quartier du Centre-Ville",
    "Quartier La Bruyère",
    "Quartier Clos d'Arcy",
    "Quartier Saint-Exupéry",
    "Quartier Rives de Seine, Pôle Gare",
    "Quartier Île de Migneaux",
    "Quartier du Technoparc",
    "Autre",
    "Je travaille à Poissy",
  ];

  return (
    <FormControl className={matClasses.formControl}>
      <InputLabel id="demo-simple-select-label">Quartier</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props.value}
        onChange={props.handleChange}
      >
        {quartiers.map((quartier) => (
          <MenuItem value={quartier} key={quartier}>
            {quartier}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const QuestionOne = () => {
  const { profileSetupData, dispatch } = useContext(ProfileSetupContext);
  return (
    <div className={classes.QuestionOne}>
      <p>Quel quartier habitez-vous ?</p>
      <DropDown
        value={profileSetupData.quartier.value}
        handleChange={(e) =>
          dispatch({ type: "SET_QUARTIER", quartier: e.target.value })
        }
      />
    </div>
  );
};

export default QuestionOne;
