import React from "react";
import classes from "./HomeToolbar.module.css";
import * as firebase from "firebase/app";
// MUI
import FaceRoundedIcon from "@material-ui/icons/FaceRounded";
import { IconButton } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// Routing
import { useHistory } from "react-router-dom";

const HomeToolbar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => window.location.reload());
    history.push("/");
  };

  const history = useHistory();
  const LogoHandler = () => {
    let path = "/";
    history.push(path);
  };
  return (
    <>
      <header className={classes.Header}>
        <header className={classes.Toolbar}>
          <div className={classes.Title}>
            <h3>DEMO COREHAB</h3>
            <h4>Parc Rouget-de-Lisle</h4>
          </div>
          <div className={classes.Logo}>
            <img
              style={{ width: "100%" }}
              alt="hand"
              src={require(`../../../../assets/homePage/LOGORDL.png`)}
            />
          </div>
        </header>
        <div className={classes.BottomSection}>
          <div className={classes.Description}>
            <h4>Et si ensemble on dessinait le parc de demain ?</h4>
            <p>
              Une application pour vous informer et donner votre avis sur le
              futur parc dans le quartier Rouget de Lisle. Bonne visite !
            </p>
          </div>
        </div>
      </header>
    </>
  );
};

export default HomeToolbar;
