import React, { useState, useEffect, useRef, useContext } from "react";
import classes from "./Travaux.module.css";
// Contexts
import { TravauxContext } from "../../contexts/TravauxContext";
import { AuthContext } from "../../contexts/AuthContext";
// Utils
import { loadAnswers } from "../../utils/TravauxUtils";
// Components
import Modal from "../../components/UI/Modal/Modal";
import IntroModal from "../../components/Travaux/HelpModals/IntroModal/IntroModal";
import Toolbar from "../../components/Travaux/Navigation/Toolbar/Toolbar";
import Filter from "../../components/Travaux/Filter/Filter";
import CardList from "../../components/Travaux/CardList/CardList";
import Footer from "../../components/Travaux/Footer/Footer";
import CardModal from "../../components/Travaux/CardModal/CardModal";
// Cards
import ConcertableLayoutOne from "../../components/Travaux/ConcertableLayouts/ConcertableLayoutOne/concertableLayoutOne";
import ConcertableSeven from "../../components/Travaux/ConcertableLayouts/ConcertableSeven/ConcertableSeven";
import ConcertableSix from "../../components/Travaux/ConcertableLayouts/ConcertableSix/ConcertableSix";
import NonConcertableLayoutOne from "../../components/Travaux/NonConcertableLayouts/NonConcertableLayoutOne/NonConcertableLayoutOne";
// MUI
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";

const scrollToRef = (ref) => window.scrollTo(0, 150);

const Travaux = () => {
  const { travauxData, dispatch } = useContext(TravauxContext);
  const { currentUser } = useContext(AuthContext);
  const { cards, selectedCard, showCard, filters } = travauxData;

  const [introModalShow, setIntroModalShow] = useState(true);

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  // Declare a new state variable with the "useState" Hook
  const [width, setWidth] = useState(window.innerWidth);

  // Card Element
  // const cardElement = (
  //   <React.Fragment>
  //     {selectedCard !== "" && cards[selectedCard].type === "concertable" ? (
  //       <CardModal show={showCard}>
  //         <ConcertableLayoutOne
  //           card={cards[selectedCard]}
  //           dispatch={dispatch}
  //           width={width}
  //           currentUser={currentUser}
  //         />
  //       </CardModal>
  //     ) : selectedCard !== "" &&
  //       cards[selectedCard].type === "nonConcertable" ? (
  //       <CardModal show={showCard}>
  //         <NonConcertableLayoutOne
  //           card={cards[selectedCard]}
  //           dispatch={dispatch}
  //           width={width}
  //           currentUser={currentUser}
  //         />
  //       </CardModal>
  //     ) : null}
  //   </React.Fragment>
  // );
  const cardElement = (
    <React.Fragment>
      {selectedCard === 5 && cards[selectedCard].type === "concertable" ? (
        <ConcertableSix
          card={cards[selectedCard]}
          dispatch={dispatch}
          width={width}
          currentUser={currentUser}
          show={showCard}
        />
      ) : selectedCard === 6 && cards[selectedCard].type === "concertable" ? (
        <ConcertableSeven
          card={cards[selectedCard]}
          dispatch={dispatch}
          width={width}
          currentUser={currentUser}
          show={showCard}
        />
      ) : selectedCard !== "" && cards[selectedCard].type === "concertable" ? (
        <ConcertableLayoutOne
          card={cards[selectedCard]}
          dispatch={dispatch}
          width={width}
          currentUser={currentUser}
          show={showCard}
        />
      ) : selectedCard !== "" &&
        cards[selectedCard].type === "nonConcertableSpecial" ? (
        <NonConcertableLayoutOne
          card={cards[selectedCard]}
          dispatch={dispatch}
          width={width}
          currentUser={currentUser}
          show={showCard}
        />
      ) : null}
    </React.Fragment>
  );

  useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
       the "width" state variable when the window size changes */
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Load data
    loadAnswers(currentUser.uid, dispatch);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);

    /* passing an empty array as the dependencies of the effect will cause this
       effect to only run when the component mounts, and not each time it updates.
       We only want the listener to be added once */
  }, []);

  if (width < 768) {
    return (
      <React.Fragment>
        <div ref={myRef}></div>
        {/* <Modal
          show={introModalShow}
          backgroundColor="#F3F2F0"
          clicked={() => setIntroModalShow(false)}
        >
          <IntroModal clicked={() => setIntroModalShow(false)} />
        </Modal> */}

        {cardElement}
        <Toolbar />
        <div className={classes.SubHeader}>
          <div className={classes.HomeLink}>
            <ArrowBackRoundedIcon
              style={{ fontSize: "1.5rem", marginTop: "-.1rem" }}
            />
            <a href="/">Revenir à la description du projet</a>
          </div>
          <Filter filters={filters} dispatch={dispatch} />
        </div>
        <div className={classes.Travaux}>
          <div className={classes.Main}>
            <div className={classes.CardListTitle}>
              <p>
                <span>Informez-vous</span> et donner votre avis sur le futur
                parc.
              </p>
              <span
                className={classes.Emoji}
                role="img"
                style={{ fontSize: "2rem", border: "none" }}
                aria-label="emoji"
              >
                👇
              </span>
            </div>
            <CardList
              filters={filters}
              cards={cards}
              dispatch={dispatch}
              executeScroll={executeScroll}
            />
            <div className={classes.HomeLink}>
              <ArrowBackRoundedIcon
                style={{
                  fontSize: "1.5rem",
                  marginTop: "-.1rem",
                  color: "#333333",
                }}
              />
              <a href="/" style={{ color: "#333333" }}>
                Revenir à la description du projet
              </a>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {/* <Modal
          show={introModalShow}
          backgroundColor="#F3F2F0"
          clicked={() => setIntroModalShow(false)}
        >
          <IntroModal clicked={() => setIntroModalShow(false)} />
        </Modal> */}
        {cardElement}
        <Toolbar />
        <div className={classes.SubHeader}>
          <div className={classes.HomeLink}>
            <ArrowBackRoundedIcon />
            <a href="/">Revenir à la description du projet</a>
          </div>
          <Filter filters={filters} dispatch={dispatch} />
        </div>
        <div className={classes.Travaux}>
          <div className={classes.Main}>
            <div className={classes.CardListTitle}>
              <p>
                <span>Informez-vous</span> et donner votre avis sur le futur
                parc.
              </p>
              <span
                className={classes.Emoji}
                role="img"
                style={{ fontSize: "2rem", border: "none" }}
                aria-label="emoji"
              >
                👇
              </span>
            </div>
            <div className={classes.MainCards}>
              <div className={classes.List}>
                <CardList
                  filters={filters}
                  cards={cards}
                  dispatch={dispatch}
                  executeScroll={executeScroll}
                />
              </div>
            </div>
            <div className={classes.HomeLink}>
              <ArrowBackRoundedIcon
                style={{
                  fontSize: "1.5rem",
                  marginTop: "-.1rem",
                  color: "#333333",
                }}
              />
              <a href="/" style={{ color: "#333333" }}>
                Revenir à la description du projet
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
};

export default Travaux;
