import * as React from "react";

function Trampoline(props) {
  return (
    <svg
      width={401}
      height={300}
      viewBox="0 0 401 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M400.6 154.75v-10.1L349 114.8 242.8 53.35l-2.2 1.25v-2.5l-39.8-23.05L42.6 120v2.45l-2.15-1.25L.3 144.3v9.75l157.8 91.5 1.75-.95v1.95l40.75 23.65h.05L349 184.55l9.55-5.55v-2.45l2.2 1.2 39.85-23z"
        fill="#ED986F"
      />
      <path
        d="M202.7 82.95V80.7L86.25 147.6l4.05 2.35 108.55 63v2.15l116.85-67.5-113-64.65z"
        fill="#616669"
      />
      <path
        d="M400.6 154.75v-10.1l-39.85 23L349 160.9 206.45 78.55l-3.75-2.15v-.05l37.9-21.75v-2.5L82.55 143.2 42.6 120v2.45l47.7 27.5c39.867-18.567 76.7-39.567 110.5-63 36.4 25.233 73.15 46.333 110.25 63.3l7.85-4.5 2 1.2 28.1 16.4 9.55 5.55-9.55 5.55-148.4 85.65-40.75-23.55 39-21.45v-2.15l-40.75 22.6L.3 144.3v9.75l157.8 91.5 1.75-.95v1.95l40.75 23.65h.05L349 184.55l9.55-5.55v-2.45l2.2 1.2 39.85-23z"
        fill="#000"
        fillOpacity={0.15}
      />
      <path
        d="M.3 144.3v9.75l157.8 91.5 1.75-.95v1.95l40.75 23.65v-10.1l-40.75-23.55 39-21.45v-2.15l-40.75 22.6L.3 144.3zm82.25 1.15v-2.25L42.6 120v2.45l39.95 23zm233.15 2.15l3.2-1.85 2 1.2 28.1 16.4 9.55 5.55v7.65l2.2 1.2v-10.1L349 160.9 206.45 78.55l-3.75-2.15v-.05l37.9-21.75v-2.5l-39.85 23.15v6.55l1.95-1.1v2.25l113 64.65z"
        fill="#000"
        fillOpacity={0.15}
      />
      <path
        d="M198.85 215.1v-2.15l-40.75 22.6v10l1.75-.95v-8.05l39-21.45zm-116.3-69.65v-2.25L42.6 120v2.45l39.95 23zm118.2-63.65l1.95-1.1v-4.35l37.9-21.75v-2.5l-39.85 23.15v6.55zm120.15 62.75v2.4l28.1 16.4 9.55 5.55v7.65l2.2 1.2v-10.1l-39.85-23.1z"
        fill="#000"
        fillOpacity={0.15}
      />
    </svg>
  );
}

export default Trampoline;
