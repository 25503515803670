import React from "react";
import classes from "./NonConcertableLayoutOne.module.css";
// MUI
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import { IconButton } from "@material-ui/core";
import { TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// Utils
import { uploadAnswer } from "../../../../utils/TravauxUtils";
// Youtube
// import ReactYoutube from "../../../../utils/ReactYoutube";
import ReactPlayer from "react-player";
import MapButton from "../../../UI/Buttons/mapButton/mapButton";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const NonConcertableLayoutOne = ({
  card,
  dispatch,
  width,
  currentUser,
  show,
}) => {
  const questions = card.questions;
  const classesMUI = useStyles();

  const handleChange = (event, questionID, cardID, questionType) => {
    // Update the answers value for the given question
    dispatch({
      type: "UPDATE_ANSWER",
      cardID,
      questionID,
      answer: event.target.value,
    });

    // Update the cards imageURL - should be run after the answer has been updated
    if (questionType !== "input") {
      dispatch({
        type: "UPDATE_IMAGE",
        cardID,
      });
    }
  };

  const handleSubmit = () => {
    let answers = {};
    questions.map((question) => {
      if (question.type === "input") {
        answers[question.id] = {
          question: question.title,
          value: question.value,
        };
      } else {
        answers[question.id] = {
          question: question.title,
          answer:
            question.options[
              question.options.findIndex(
                (option) => option.value === question.value
              )
            ].label,
          value: question.value,
        };
      }
    });
    answers["comment"] = card.comment;
    uploadAnswer(currentUser.uid, card.id, answers, dispatch);
  };

  // plan button
  const plan = (
    <div style={{ marginTop: "1rem" }}>
      <MapButton plan={card.plan}>Voir le plan</MapButton>
    </div>
  );

  // Header =============================================================================================
  const header = (
    <header className={classes.CardHeader}>
      <div className={classes.CardTitle}>
        <h3>{card.titleStrong}</h3>
        <h4>{card.titleWeak}</h4>
      </div>
      {plan}
    </header>
  );

  // Video Preview =============================================================================================
  // const imgSrc = questions[0].type === "input" ? card.thumbnail : card.imgSrc;
  const videoPreview = (
    <div className={classes.VideoPreview}>
      <ReactPlayer
        url={card.video.url}
        loop={true}
        width={card.video.width}
        height={card.video.height}
        playing={true}
      />
    </div>
  );

  // Description  =============================================================================================
  const description = (
    <div className={classes.Description}>
      <p>{card.description}</p>
    </div>
  );

  // DescriptionPlus =========================================================================================
  const descriptionPlus =
    card.descriptionPlus !== "" ? (
      <div className={classes.DescriptionPlus}>
        {card.descriptionPlus.map((item) => (
          <p key={item} style={{ marginBottom: ".5rem" }}>
            <span>・</span> {item}
          </p>
        ))}
      </div>
    ) : null;

  // Plan Masse  =============================================================================================
  // const planMasse = (
  //   <div className={classes.NCSection}>
  //     <div className={classes.DescriptionTitle}>
  //       <p>
  //         <span className={classes.Emoji} role="img" aria-label="emoji">
  //           🧭
  //         </span>{" "}
  //         Localisation des travaux
  //       </p>
  //     </div>

  //     <div className={classes.MapImage}>
  //       <img
  //         style={{ width: "100%" }}
  //         alt="map"
  //         src={require(`../../../../assets/travaux/${card.planMasse}`)}
  //       />
  //     </div>
  //   </div>
  // );

  // Commentaire  =============================================================================================
  const commentaire = (
    <div className={classes.CommentSection}>
      <TextField
        onChange={(e) =>
          dispatch({
            type: "SET_COMMENT",
            cardID: card.id,
            comment: e.target.value,
          })
        }
        value={card.comment}
        variant="outlined"
        label="J'ai un commentaire !"
        style={{ marginBottom: "0.8em", width: "100%" }}
        color="secondary"
      />
    </div>
  );

  // Button Valider
  const validerButton = (
    <div className={classes.ValiderButton}>
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        style={{ width: "7em", color: "white", fontWeight: "bold" }}
        onClick={handleSubmit}
        disabled={card.comment === ""}
      >
        Valider
      </Button>
    </div>
  );

  // Bouton Retour ===========================================================
  const revenirButton = (
    <div className={classes.RevenirButton}>
      <Button
        variant="contained"
        size="medium"
        style={{
          width: "7em",
          color: "#333333",
          fontWeight: "bold",
          backgroundColor: "#E0E0E0",
        }}
        onClick={() => dispatch({ type: "SET_HIDECARD", show: false })}
      >
        Revenir
      </Button>
    </div>
  );

  // Notices =============================================================
  const notices = (
    <div className={classes.Notices}>
      <p>{card.noticeOne}</p>
      <p>{card.noticeTwo}</p>
    </div>
  );

  // Returned JSX ======================================================================

  if (width < 910) {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{ overflow: "scroll" }}
          open={show}
          onClose={() => dispatch({ type: "SET_HIDECARD", show: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <div className={classes.Card}>
              {header}
              {videoPreview}

              <div className={classes.CardContent}>
                <div className={classes.QuestionsHeader}>
                  <h3>Description des travaux</h3>
                </div>
                {description}
                {descriptionPlus}
                {/* {planMasse} */}
                {/* {commentaire} */}
                <div className={classes.Buttons}>
                  {revenirButton}
                  {/* {validerButton} */}
                </div>
                {notices}
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classesMUI.modal}
          open={show}
          onClose={() => dispatch({ type: "SET_HIDECARD", show: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <div className={classes.Card}>
              <div className={classes.LeftSection}>
                {header}
                {videoPreview}

                {/* {planMasse} */}
              </div>
              <div className={classes.RightSection}>
                <div className={classes.CardContent}>
                  <div>
                    <div className={classes.QuestionsHeader}>
                      <h3>Description des travaux</h3>
                    </div>
                    {description}
                  </div>
                  {descriptionPlus}
                  <div>
                    {/* <div className={classes.RightFooter}>{commentaire}</div> */}
                    <div className={classes.Buttons}>
                      {revenirButton}
                      {/* {validerButton} */}
                    </div>
                    {notices}
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
};

export default NonConcertableLayoutOne;
