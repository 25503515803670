import React, { useState } from "react";
import classes from "./CardCreateReclamation.module.css";
// MUI
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ControlPointRoundedIcon from "@material-ui/icons/ControlPointRounded";
// Component
import ModalCreateReclamation from "../../ModalCreateReclamation/ModalCreateReclamation";

const CardCreateReclamation = ({ setReclamations }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <ModalCreateReclamation
        show={show}
        setShow={setShow}
        setReclamations={setReclamations}
      />
      <div className={classes.Card} onClick={() => setShow(true)}>
        <div className={classes.Thumbnail}>
          <ControlPointRoundedIcon
            style={{ fontSize: "7rem", color: "#3B4578" }}
          />
        </div>

        <div className={classes.Main}>
          <div className={classes.Titre}>
            <p>
              <span>Poster </span> votre dessin / idée pour le futur parc.
            </p>
          </div>
          {/* <div className={classes.Description}>
            <span>Cliquez sur la carte pour postez votre idée.</span>
          </div> */}

          <div className={classes.TagContainer}>
            <div className={classes.Tag} style={{ backgroundColor: "#3B4578" }}>
              <EditOutlinedIcon style={{ fontSize: ".8rem" }} />
              <p>Dessin / Idée</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardCreateReclamation;
