export const ProfileSetupReducer = (state, action) => {
  switch (action.type) {
    case "SET_NAME":
      return {
        ...state,
        name: {
          ...state.name,
          value: action.name,
        },
      };

    case "SET_LASTNAME":
      return {
        ...state,
        lastName: {
          ...state.lastName,
          value: action.lastName,
        },
      };

    case "SET_BIRTHYEAR":
      return {
        ...state,
        birthYear: {
          ...state.birthYear,
          value: action.birthYear,
        },
      };

    case "SET_QUARTIER":
      return {
        ...state,
        quartier: {
          ...state.quartier,
          value: action.quartier,
        },
      };

    case "SET_FLATSIZE":
      const newFlatOptions = state.flatSize.elementConfig.options.map(
        (option) => {
          if (option.value === action.flatSize) {
            return {
              ...option,
              checked: true,
            };
          } else {
            return {
              ...option,
              checked: false,
            };
          }
        }
      );

      return {
        ...state,
        flatSize: {
          ...state.flatSize,
          elementConfig: {
            ...state.flatSize.elementConfig,
            options: newFlatOptions,
          },
          value: action.flatSize,
        },
      };

    case "SET_NUMCARS":
      const newCarsOptions = state.numCars.elementConfig.options.map(
        (option) => {
          if (option.value === action.numCars) {
            return {
              ...option,
              checked: true,
            };
          } else {
            return {
              ...option,
              checked: false,
            };
          }
        }
      );

      return {
        ...state,
        numCars: {
          ...state.numCars,
          elementConfig: {
            ...state.numCars.elementConfig,
            options: newCarsOptions,
          },
          value: action.numCars,
        },
      };

    case "SET_NUMMOTORS":
      const newMotorsOptions = state.numMotors.elementConfig.options.map(
        (option) => {
          if (option.value === action.numMotors) {
            return {
              ...option,
              checked: true,
            };
          } else {
            return {
              ...option,
              checked: false,
            };
          }
        }
      );
      return {
        ...state,
        numMotors: {
          ...state.numMotors,
          elementConfig: {
            ...state.numMotors.elementConfig,
            options: newMotorsOptions,
          },
          value: action.numMotors,
        },
      };

    case "SET_NUMBIKES":
      const newBikesOptions = state.numBikes.elementConfig.options.map(
        (option) => {
          if (option.value === action.numBikes) {
            return {
              ...option,
              checked: true,
            };
          } else {
            return {
              ...option,
              checked: false,
            };
          }
        }
      );
      return {
        ...state,
        numBikes: {
          ...state.numBikes,
          elementConfig: {
            ...state.numBikes.elementConfig,
            options: newBikesOptions,
          },
          value: action.numBikes,
        },
      };

    case "SET_HABITORMESSON":
      const newHabitOrmessonOptions = state.habitOrmesson.elementConfig.options.map(
        (option) => {
          if (option.value === action.habitOrmesson) {
            return {
              ...option,
              checked: true,
            };
          } else {
            return {
              ...option,
              checked: false,
            };
          }
        }
      );
      return {
        ...state,
        habitOrmesson: {
          ...state.habitOrmesson,
          elementConfig: {
            ...state.habitOrmesson.elementConfig,
            options: newHabitOrmessonOptions,
          },
          value: action.habitOrmesson,
        },
      };

    case "SET_HABITATION":
      const newHabitationOptions = state.habitation.elementConfig.options.map(
        (option) => {
          if (option.value === action.habitation) {
            return {
              ...option,
              checked: true,
            };
          } else {
            return {
              ...option,
              checked: false,
            };
          }
        }
      );
      return {
        ...state,
        habitation: {
          ...state.habitation,
          elementConfig: {
            ...state.habitation.elementConfig,
            options: newHabitationOptions,
          },
          value: action.habitation,
        },
      };
    default:
      return state;
  }
};
