import React from "react";
import classes from "./ReclamationContent.module.css";
// MUI
import { Button } from "@material-ui/core";

const StepOne = ({ setStep, setShow }) => {
  return (
    <div className={classes.StepContent}>
      <p>
        Bienvenue dans la fiche dessin / idée!
        <br />
        <br />
        Dessiner le parc de demain, votre aire de jeux, votre mobilier… ou
        écrivez nous votre idée !
        <br />
        <br />
        Vous pouvez poster autant de dessins / d'idées que vous souhaitez, pour
        cela créer une nouvelle fiche dessin / idée.
      </p>
      <div className={classes.IntroImage}>
        <img
          style={{ width: "100%" }}
          alt="salon"
          src={require(`../../../../assets/homePage/concertation.svg`)}
        />
      </div>
      <div className={classes.Buttons}>
        <Button
          color="primary"
          size="medium"
          style={{
            width: "6rem",
            color: "#333333",
            backgroundColor: "#E0E0E0",
          }}
          onClick={() => setShow(false)}
        >
          Fermer
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          style={{ width: "8rem", color: "white" }}
          onClick={() => setStep(1)}
        >
          Commencer
        </Button>
      </div>
    </div>
  );
};

export default StepOne;
