import React, { useState, useContext } from "react";
import classes from "./ModalUserReclamation.module.css";
// Firebase
import { AuthContext } from "../../../contexts/AuthContext";
// MUI
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import LinearProgress from "@material-ui/core/LinearProgress";
// Components
import UserReclamationContent from "./ReclamationContent/UserReclamationContent";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ModalUserReclamation = ({ show, setShow, reclamation }) => {
  const classesMUI = useStyles();

  let content;
  let modalProgress;
  let modalTitle;
  if (reclamation) {
    content = (
      <UserReclamationContent
        setShow={setShow}
        tagOne={reclamation.tagOne}
        tagTwo={reclamation.tagTwo}
        title={reclamation.title}
        description={reclamation.description}
        pictures={reclamation.pictures}
        answer={reclamation.answer}
      />
    );
    modalProgress = reclamation.answer ? (
      <p style={{ color: "#EE9834" }}>Vous avez reçu une réponse</p>
    ) : (
      <p style={{ color: "#EE9834" }}>Votre idée est en cours de traitement</p>
    );
    modalTitle = (
      <h3 className={classes.ModalTitle}>Récapitulatif de votre idée</h3>
    );
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classesMUI.modal}
        open={show}
        onClose={() => setShow(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <div className={classes.ModalContent}>
            <div>{modalProgress}</div>
            <div>{modalTitle}</div>
            {content}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ModalUserReclamation;
