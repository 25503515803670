import React, { useState } from "react";
import classes from "./Projet.module.css";
// Components
import Articles from "./Articles/Articles";
import UploadDocument from "./UploadDocument/UploadDocument";
// MUI
import FormatQuoteRoundedIcon from "@material-ui/icons/FormatQuoteRounded";

const Projet = ({ width, claims, clicked }) => {
  const [pdfs, setPdfs] = useState();
  return (
    <div className={classes.Projet}>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionSvg}>
          <img
            style={{ width: "100%" }}
            alt="concertation"
            src={require(`../../../assets/homePage/concertation.svg`)}
          />
        </div>
        <div className={classes.BackgroundShape}></div>
        <div className={classes.Description}>
          <div className={classes.LetterTitle}>
            <p style={{ fontSize: "1.5rem" }}>
              <strong>L'ÉcoQuartier se construit avec les Pisciacais</strong>
            </p>
            <div className={classes.LetterPhoto}>
              <img
                style={{ width: "100%" }}
                alt="Photo"
                src={require(`../../../assets/homePage/photo-maire.png`)}
              />
            </div>
          </div>
          {/* <FormatQuoteRoundedIcon
            style={{ fontSize: "3rem", marginTop: "-3rem", color: "#5b5855" }}
          /> */}
          <p>« Chères Pisciacaises, chers Pisciacais</p>
          <p>
            Après de nombreuses années d’attente, voir se concrétiser chaque
            jour un peu plus l'ÉcoQuartier Rouget-de-Lisle est une immense
            satisfaction, une grande fierté pour notre municipalité. La
            livraison des premiers programmes immobiliers – l’ensemble Signature
            et la résidence étudiante Geneviève Brousset -, des premiers
            aménagements – la rue des Prés, la promenade Edmond-Michelet –
            témoignent d’ores et déjà de la qualité et de l’exigence qui ne
            cessera de prévaloir sur ce quartier, future vitrine de notre cité.
          </p>
          <p>
            Un Écoquartier exemplaire en termes d’environnement, de traitement
            des déchets, d’architecture, d’énergie, d’aménagement urbain et
            paysager, mais aussi en termes de concertation. A Poissy nous
            n’oublions pas que ce sont les habitants qui font la cité. Et dès la
            pose de la première pierre, nous avons eu à cœur de pleinement
            associer les Pisciacais, avec le soutien de l’aménageur CITALLIOS.
          </p>
          <p>
            Dans la continuité de la Maison du Projet édifiée pour informer et
            échanger avec les riverains et habitants, nous lançons aujourd’hui
            une grande concertation numérique sur le visage et les usages du
            futur parc central de Rouget-de-Lisle. Nom du parc, mobilier, aire
            de jeux, ambiance, usage… : autant de choix fondamentaux pour
            affiner la personnalité de ce parc central qui sera le cœur battant
            de l’écoquartier.
          </p>
          <p>L’avenir de Poissy se dessine avec vous. Bonne concertation. »</p>
          {/* <FormatQuoteRoundedIcon
            style={{ fontSize: "3rem", marginTop: "-3rem", color: "#5b5855" }}
          /> */}

          <p style={{ fontSize: "1.5rem", marginBottom: "0" }}>Karl Olive</p>
          <ul>
            <li>Maire de poissy</li>
            <li>
              Vice-président de la Communauté urbaine Grand Paris Seine & Oise
            </li>
            <li>Vice-président du Conseil départemental des Yvelines</li>
          </ul>
        </div>
      </div>
      <div className={classes.Articles}>
        <div className={classes.ArticlesHeader}>
          <h4 style={{ marginBottom: "1.5rem" }}>Actualités</h4>
          {claims && claims.admin && (
            <UploadDocument setPdfs={setPdfs} claims={claims} />
          )}
        </div>
        <Articles setPdfs={setPdfs} pdfs={pdfs} claims={claims} />
      </div>
    </div>
  );
};

export default Projet;
