import { db } from "../config/firebase";

export const addLocInfo = (profileSetupData, locataireID) => {
  const { name, lastName, birthYear, quartier } = profileSetupData;
  const locInfo = {
    fullName: `${name.value} ${lastName.value}`,
    birthYear: birthYear.value,
    quartier: quartier.value,
  };

  db.collection("locataires")
    .doc(`anon-${locataireID}`)
    .update(locInfo)
    .then((resp) => {
      console.log("Doc successfully updated");
    })
    .catch((err) => {
      console.log("Error while updating doc :", err);
    });
};
