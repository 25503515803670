import React from "react";
import classes from "./concertableLayoutOne.module.css";
// Components
import ConcertableQuestion from "../ConcertableUtils/concertableQuestion";
// MUI
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";
import { IconButton } from "@material-ui/core";
import { TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// Utils
import { uploadAnswer } from "../../../../utils/TravauxUtils";
import MapButton from "../../../UI/Buttons/mapButton/mapButton";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ConcertableLayoutOne = ({ card, dispatch, width, currentUser, show }) => {
  const questions = card.questions;
  const classesMUI = useStyles();

  const handleChange = (event, questionID, cardID, questionType) => {
    if (questionType === "checkbox") {
      dispatch({
        type: "UPDATE_ANSWER_CHECKBOX",
        cardID,
        questionID,
        event,
      });

      if (questionType !== "input") {
        dispatch({
          type: "UPDATE_IMAGE_CHECKBOX",
          cardID,
          event,
        });
      }
    } else {
      // Update the answers value for the given question
      dispatch({
        type: "UPDATE_ANSWER",
        cardID,
        questionID,
        answer: event.target.value,
      });

      // Update the cards imageURL - should be run after the answer has been updated
      if (questionType !== "input") {
        dispatch({
          type: "UPDATE_IMAGE",
          cardID,
        });
      }
    }
  };

  // const handleSubmit = () => {
  //   let answers = {};
  //   questions.map((question) => {
  //     answers[question.id] = {
  //       question: question.title,
  //       answer:
  //         question.options[
  //           question.options.findIndex(
  //             (option) => option.value === question.value
  //           )
  //         ].label,
  //       value: question.value,
  //     };
  //   });
  //   answers["comment"] = card.comment;
  //   uploadAnswer(currentUser.uid, card.id, answers, dispatch);

  const handleSubmit = () => {
    let answers = {};

    if (questions[0].type === "checkbox") {
      questions.map((question) => {
        if (question.type === "input") {
          answers[question.id] = {
            question: question.title,
            value: question.value,
          };
        } else {
          answers[question.id] = {
            question: question.title,
            value: question.value,
          };
        }
      });
    } else {
      questions.map((question) => {
        if (question.type === "input") {
          answers[question.id] = {
            question: question.title,
            value: question.value,
          };
        } else {
          answers[question.id] = {
            question: question.title,
            answer:
              question.options[
                question.options.findIndex(
                  (option) => option.value === question.value
                )
              ].label,
            value: question.value,
          };
        }
      });
    }

    answers["comment"] = card.comment;
    uploadAnswer(currentUser.uid, card.id, answers, dispatch);
  };

  // plan button
  const plan = (
    <div style={{ marginTop: "1rem" }}>
      <MapButton plan={card.plan}>Voir le plan</MapButton>
    </div>
  );

  // Header =============================================================================================
  const header = (
    <header className={classes.CardHeader}>
      <div className={classes.CardTitle}>
        <h3>{card.titleStrong}</h3>
        <h4>{card.titleWeak}</h4>
      </div>
      {plan}
    </header>
  );

  // Image Preview =============================================================================================
  const imgSrc = questions[0].type === "input" ? card.thumbnail : card.imgSrc;
  const imagePreview = (
    <div className={classes.ImagePreview}>
      <img
        style={{ width: "100%" }}
        alt="salon"
        src={require(`../../../../assets/travaux/${imgSrc}`)}
      />
    </div>
  );

  // Description  =============================================================================================
  const description = (
    <div className={classes.Description}>
      <p>{card.description}</p>
    </div>
  );

  // Conditional =======================================================================================
  const conditional = card.conditional.isConditional ? (
    <ConcertableQuestion
      question={card.conditional}
      cardID={card.id}
      handleChange={dispatch}
    />
  ) : null;

  // Questions  =============================================================================================
  const questionsElement = !card.conditional.isConditional ? (
    <React.Fragment>
      {questions.map((question) => {
        return (
          <ConcertableQuestion
            key={question.id}
            question={question}
            cardID={card.id}
            handleChange={handleChange}
          />
        );
      })}
    </React.Fragment>
  ) : card.conditional.value ? (
    <React.Fragment>
      {questions.map((question) => {
        return (
          <ConcertableQuestion
            key={question.id}
            question={question}
            cardID={card.id}
            handleChange={handleChange}
          />
        );
      })}
    </React.Fragment>
  ) : null;

  // Plan Masse  =============================================================================================
  // const planMasse = (
  //   <div className={classes.NCSection}>
  //     <div className={classes.DescriptionTitle}>
  //       <p>
  //         <span className={classes.Emoji} role="img" aria-label="emoji">
  //           🧭
  //         </span>{" "}
  //         Localisation des travaux
  //       </p>
  //     </div>

  //     <div className={classes.MapImage}>
  //       <img
  //         style={{ width: "100%" }}
  //         alt="map"
  //         src={require(`../../../../assets/travaux/${card.planMasse}`)}
  //       />
  //     </div>
  //   </div>
  // );

  // Commentaire  =============================================================================================
  // questions[0].value
  const commentaire =
    questions[0].value === "q1rep100" ? (
      <div className={classes.CommentSection}>
        <TextField
          onChange={(e) =>
            dispatch({
              type: "SET_COMMENT",
              cardID: card.id,
              comment: e.target.value,
            })
          }
          value={card.comment}
          variant="outlined"
          label="Veuillez préciser votre suggestion"
          style={{ marginBottom: "0.8em", width: "100%" }}
          color="secondary"
        />
      </div>
    ) : null;

  // Button Valider
  console.log(
    Object.values(card.questions[0].value).filter((v) => v).length >
      card.questions[0].maxChoices
  );
  let questionValid =
    card.questions[0].type === "checkbox"
      ? Object.values(card.questions[0].value).filter((v) => v).length >
        card.questions[0].maxChoices
      : card.comment === "" && card.questions[0].value === "";
  // if(card.questions[0].type === "checkbox"){
  //   questionValid = Object.values(card.questions[0].value).filter((v) => v).length >
  //   card.questions[0].maxChoices
  // } else {
  //   questionValid = card.comment === "" && card.questions[0].value === ""
  // }
  const validerButton = (
    <div className={classes.ValiderButton}>
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        style={{ width: "7em", color: "white", fontWeight: "bold" }}
        onClick={handleSubmit}
        disabled={questionValid}
      >
        Valider
      </Button>
    </div>
  );

  // Bouton Retour ===========================================================
  const revenirButton = (
    <div className={classes.RevenirButton}>
      <Button
        variant="contained"
        size="medium"
        style={{
          width: "7em",
          color: "#333333",
          fontWeight: "bold",
          backgroundColor: "#E0E0E0",
        }}
        onClick={() => dispatch({ type: "SET_HIDECARD", show: false })}
      >
        Revenir
      </Button>
    </div>
  );

  // Notices =============================================================
  const notices = (
    <div className={classes.Notices}>
      <p>{card.noticeOne}</p>
      <p>{card.noticeTwo}</p>
    </div>
  );

  // Returned JSX ======================================================================

  if (width < 910) {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{ overflow: "scroll" }}
          open={show}
          onClose={() => dispatch({ type: "SET_HIDECARD", show: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <div className={classes.Card}>
              {header}
              {imagePreview}
              {description}

              <div className={classes.CardContent}>
                <div className={classes.QuestionsHeader}>
                  <h3>Personnaliser les travaux</h3>
                </div>
                {conditional}
                {questionsElement}
                {/* {planMasse} */}
                {commentaire}
                <div className={classes.Buttons}>
                  {revenirButton}
                  {validerButton}
                </div>
                {notices}
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classesMUI.modal}
          open={show}
          onClose={() => dispatch({ type: "SET_HIDECARD", show: false })}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <div className={classes.Card}>
              <div className={classes.LeftSection}>
                {header}
                {imagePreview}
                {description}
                {/* {planMasse} */}
              </div>
              <div className={classes.RightSection}>
                <div className={classes.CardContent}>
                  <div>
                    <div className={classes.QuestionsHeader}>
                      <h3>Personnaliser les travaux</h3>
                    </div>
                    {conditional}
                    {questionsElement}
                  </div>

                  <div>
                    <div className={classes.RightFooter}>{commentaire}</div>
                    <div className={classes.Buttons}>
                      {revenirButton}
                      {validerButton}
                    </div>
                    {notices}
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  }
};

export default ConcertableLayoutOne;
