import * as React from "react";

function IconAdd(props) {
  return (
    <svg
      width={141}
      height={141}
      viewBox="0 0 141 141"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M69.453 104c-2.496 0-4.32-.336-5.472-1.008-1.152-.672-1.728-1.776-1.728-3.312V77.792H40.365c-1.536 0-2.64-.576-3.312-1.728-.672-1.152-1.008-2.976-1.008-5.472v-1.008c0-2.496.336-4.32 1.008-5.472.672-1.152 1.776-1.728 3.312-1.728h21.888V40.208c0-1.536.576-2.64 1.728-3.312 1.152-.672 2.976-1.008 5.472-1.008h2.016c2.496 0 4.32.336 5.472 1.008 1.152.672 1.728 1.776 1.728 3.312v22.176h22.176c1.536 0 2.64.576 3.312 1.728.672 1.152 1.008 2.976 1.008 5.472v1.008c0 2.496-.336 4.32-1.008 5.472-.672 1.152-1.776 1.728-3.312 1.728H78.669V99.68c0 1.536-.576 2.64-1.728 3.312-1.152.672-2.976 1.008-5.472 1.008h-2.016z"
        fill="#fff"
      />
      <circle cx={70.913} cy={70.677} r={64.5} stroke="#fff" strokeWidth={11} />
    </svg>
  );
}

export default IconAdd;
