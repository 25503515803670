import { db } from "../config/firebase";

// Load results from firebase
export const loadResults = ({ dispatch }) => {
  db.collection("locataires")
    .get()
    .then((snapshot) => {
      dispatch({ type: "SET_PARTICIPANTS", value: snapshot.size });
      // let csv = "Nom,Mobile,Réference locataire,Date\n";
      // snapshot.forEach((doc) => {
      //   csv += `${doc.data().fullName},${doc.data().numTel},${
      //     doc.data().refLoc
      //   },${doc.data().dateCreation}`;
      //   csv += "\n";
      // });
      // console.log(csv);
    })
    .catch((err) => console.log(err));

  db.collection("resultats")
    .get()
    .then((snapshot) => {
      dispatch({ type: "SET_REPONSES", value: snapshot.size });
    })
    .catch((err) => console.log(err));
};

export const loadStats = () => {
  let cardsData = {
    c1: {
      comments: [],
      q1: {},
    },
    c2: {
      comments: [],
      q1: {},
    },
    c3: {
      comments: [],
      q1: {},
    },
    c4: {
      comments: [],
      q1: {},
    },
    c5: {
      comments: [],
      q1: {},
    },
    c6: {
      comments: [],
      q1: {},
    },
    c7: {
      comments: [],
      q1: {},
    },
    c8: {
      comments: [],
      q1: {},
    },
    c9: {
      comments: [],
      q1: {},
    },
  };
  // radio
  let cards = ["c1", "c2", "c4", "c6", "c7", "c8", "c9"];
  // checkbox
  // let cards = ["c3", "c5"];
  // select
  // let cards = ["c6", "c7"];

  let questions = ["q1", "q2", "q3", "q4", "q5", "q6"];

  var resultsQuery = db.collection("resultats");
  resultsQuery = resultsQuery.where("cardID", "==", "c1");

  // radio
  cards.map((card) => {
    var resultsQuery = db.collection("resultats");
    resultsQuery = resultsQuery.where("cardID", "==", card);

    resultsQuery
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          // Ajouter les commentaires
          if ("comment" in doc.data().answers) {
            if (doc.data().answers.comment !== "") {
              cardsData[doc.data().cardID].comments.push(
                doc.data().answers.comment
              );
            }
          }
          // Ajouter le nombre de réponses
          let value = "";

          questions.map((question) => {
            if (question in doc.data().answers) {
              value = doc.data().answers[question].value;
              console.log("[value]", value);
              if (
                typeof cardsData[doc.data().cardID][question][value] !==
                "undefined"
              ) {
                cardsData[doc.data().cardID][question][value] += 1;
              } else {
                cardsData[doc.data().cardID][question][value] = 1;
              }
            }
          });
        });
        console.log(cardsData);
      })
      .catch((err) => console.log(err));
  });

  // select
  // let selectedChoices = {};
  // var resultsQuery = db.collection("resultats");
  // resultsQuery = resultsQuery.where("cardID", "==", "c6");

  // resultsQuery
  //   .get()
  //   .then((snapshot) => {
  //     snapshot.forEach((doc) => {
  //       // Ajouter les commentaires
  //       if ("comment" in doc.data().answers) {
  //         if (doc.data().answers.comment !== "") {
  //           cardsData[doc.data().cardID].comments.push(
  //             doc.data().answers.comment
  //           );
  //         }
  //       }

  //       // Ajouter le nombre de réponses
  //       if (typeof selectedChoices[doc.data().answers.choix1] !== "undefined") {
  //         selectedChoices[doc.data().answers.choix1] += 1;
  //       } else {
  //         selectedChoices[doc.data().answers.choix1] = 1;
  //       }

  //       if (typeof selectedChoices[doc.data().answers.choix2] !== "undefined") {
  //         selectedChoices[doc.data().answers.choix2] += 1;
  //       } else {
  //         selectedChoices[doc.data().answers.choix2] = 1;
  //       }

  //       if (typeof selectedChoices[doc.data().answers.choix3] !== "undefined") {
  //         selectedChoices[doc.data().answers.choix3] += 1;
  //       } else {
  //         selectedChoices[doc.data().answers.choix3] = 1;
  //       }
  //     });
  //     console.log(selectedChoices);
  //   })
  //   .catch((err) => console.log(err));
};
