import React, { useState } from "react";
import classes from "./mapButton.module.css";
// MUI
import { TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const MapButton = ({ plan, children }) => {
  const classesMUI = useStyles();
  const [show, setShow] = useState(false);
  return (
    <>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classesMUI.modal}
          open={show}
          onClose={() => setShow(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={show}>
            <div className={classes.Modal}>
              <img
                style={{ width: "100%" }}
                alt="hand"
                src={require(`../../../../assets/plans/${plan}`)}
              />
            </div>
          </Fade>
        </Modal>
      </div>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        style={{ color: "white", fontWeight: "bold" }}
        onClick={() => setShow(true)}
      >
        {children}
      </Button>
    </>
  );
};

export default MapButton;
