import React from "react";

export const CalendarData = [
  {
    id: 0,
    emoji: "🚶‍♀️",
    title: "Aménagement du secteur 2 de la ZAC",
    date: "2021 -> 2024",
    duration: "3 ans",
    plan: "Carte_Phase-2.svg",
    substeps: [
      {
        id: 0,
        emoji: "🗺️",
        title: "",
        date: "2021 -> 2024",
        duration: "3 ans",
        description: "Aménagement de la continuité de l’allée Rouget De Lisle.",
      },
      {
        id: 1,
        emoji: "🚶‍♀️",
        title: "",
        date: "2021 -> 2024",
        duration: "3 ans",
        description: "Aménagement de la promenade Edmond Michelet.",
      },
      {
        id: 2,
        emoji: "👪",
        title: "",
        date: "2021 -> 2024",
        duration: "3 ans",
        description:
          "Création de la sente Cam DIOP, de l’aire de jeux pour les petits, d’une première partie de la prairie, de l’espace « écrin ».",
      },
      {
        id: 3,
        emoji: "👷",
        title: "",
        date: "2021 -> 2024",
        duration: "3 ans",
        description: "Réaménagement de la rue Bongard en voie partagée.",
      },
      {
        id: 4,
        emoji: "👷",
        title: "",
        date: "2024 -> 2028",
        duration: "4 ans",
        description: (
          <>
            Construction des programmes immobiliers <br />F (Bel-Isle), G1 et D.
          </>
        ),
      },
    ],
  },
  {
    id: 1,
    emoji: "👷",
    title: "Aménagement du secteur 3 de la ZAC",
    date: "2024 -> 2028",
    duration: "4 ans",
    plan: "Carte_Phase-3.svg",
    substeps: [
      {
        id: 0,
        emoji: "👷",
        title: "",
        date: "2024 -> 2028",
        duration: "4 ans",
        description: "Fin de l’aménagement de l’allée Rouget De Lisle",
      },
      {
        id: 1,
        emoji: "👷",
        title: "",
        date: "2024 -> 2028",
        duration: "4 ans",
        description: "Aménagement de la fin de la promenade Edmond Michelet.",
      },
      {
        id: 2,
        emoji: "👪",
        title: "",
        date: "2024 -> 2028",
        duration: "4 ans",
        description: "L’aire de jeux pour les grands.",
      },
      {
        id: 3,
        emoji: "🍃",
        title: "",
        date: "2024 -> 2028",
        duration: "4 ans",
        description: "Une deuxième partie de la prairie.",
      },
      {
        id: 4,
        emoji: "👷",
        title: "",
        date: "2024 -> 2028",
        duration: "4 ans",
        description: (
          <>
            Construction des programmes immobiliers <br />
            G2 et J.
          </>
        ),
      },
    ],
  },
  {
    id: 2,
    emoji: "🛍️",
    title: "Aménagement du secteur 4 de la ZAC",
    date: "2028 -> 2030",
    duration: "2 ans",
    plan: "Carte_Phase-4.svg",
    substeps: [
      {
        id: 0,
        emoji: "👷",
        title: "",
        date: "2028 -> 2030",
        duration: "2 ans",
        description:
          "Aménagement du parc aux abords des programmes immobiliers de la phase 4.",
      },
      {
        id: 1,
        emoji: "🛍️",
        title: "",
        date: "2028 -> 2030",
        duration: "2 ans",
        description:
          "Aménagement de la première partie de la placette commerciale St Sébastien.",
      },
      {
        id: 3,
        emoji: "👷",
        title: "",
        date: "2024 -> 2028",
        duration: "4 ans",
        description: "Construction des programmes immobiliers K.",
      },
    ],
  },
  {
    id: 3,
    emoji: "🍃",
    title: "Aménagement du secteur 5 de la ZAC",
    date: "2030 -> 2035",
    duration: "5 ans",
    plan: "Carte_Phase-5.svg",
    substeps: [
      {
        id: 0,
        emoji: "🛍️",
        title: "",
        date: "2030 -> 2035",
        duration: "5 ans",
        description:
          "Aménagement de la fin de la placette commerciale St Sébastien.",
      },
      {
        id: 1,
        emoji: "🍃",
        title: "",
        date: "2030 -> 2035",
        duration: "5 ans",
        description: "Aménagement de la fin de la grande prairie.",
      },
      {
        id: 2,
        emoji: "👷",
        title: "",
        date: "2024 -> 2028",
        duration: "4 ans",
        description: (
          <>
            Construction des programmes immobiliers <br />
            I, H et L.
          </>
        ),
      },
    ],
  },
];
