import React from "react";
import classes from "./CalendarDesktop.module.css";
// Components
import CalendarCard from "../CalendarCard/CalendarCard";
import CalendarModal from "../CalendarModal/CalendarModal";

const CalendarDesktop = ({
  CalendarData,
  selectedCard,
  setSelectedCard,
  show,
  setShow,
}) => {
  return (
    <div className={classes.Calendar}>
      <div className={classes.CalendarTitle}>
        <p>
          <span>Le calendrier</span> des travaux
        </p>
      </div>
      <div className={classes.Main}>
        <div className={classes.CardsList}>
          {CalendarData.map((step, index) => (
            <CalendarCard
              emoji={step.emoji}
              title={step.title}
              index={index}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              key={step.id}
              setShow={setShow}
            />
          ))}
        </div>
        <div className={classes.Modal}>
          <CalendarModal step={CalendarData[selectedCard]} />
        </div>
      </div>
      <div className={classes.CalendarTitle} style={{ marginTop: "4rem" }}>
        <p>
          <span>Plan schématique</span> des secteurs
        </p>
      </div>
      <div className={classes.PlanSchematique}>
        <img
          style={{ width: "100%" }}
          alt="hand"
          src={require(`../../../../assets/homePage/Carte-Phase-Total.png`)}
        />
      </div>
    </div>
  );
};

export default CalendarDesktop;
