import * as React from "react";

function Cabane(props) {
  return (
    <svg
      width={386}
      height={414}
      viewBox="0 0 386 414"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#000"
        fillOpacity={0.15}
        d="M205.609 173.374l179.584 103.683L180.1 395.469.514 291.786z"
      />
      <path d="M81.75 299.685H302.5v-113.25H81.75v113.25z" fill="#272C2F" />
      <path
        d="M352.95 284.585v-161.7l-80.05-26.7-80 118.95-159.8-91.9v161.6l159.8 92.4 39.95-23.1v-9.85l51.5-29.9v-128.8l5.4-3.15v135.1l3.15 1.8 60.05-34.75zM132 225.935v58.8l-42.65-24.3v-59.2l42.65 24.7zm119.75 6.7c3-5.267 6.65-9.15 10.95-11.65 3.667-2.067 6.867-2.633 9.6-1.7.067.033.117.05.15.05.467.4.883.85 1.25 1.35 1.367 1.867 2.05 4.45 2.05 7.75 0 4.967-1.533 10.083-4.6 15.35-3.033 5.233-6.7 9.1-11 11.6-3.767 2.2-7.033 2.75-9.8 1.65a5.601 5.601 0 01-1.1-1.25c-1.367-1.9-2.05-4.517-2.05-7.85 0-4.967 1.517-10.067 4.55-15.3zm7.75 53.7v-2.35l20.8-11.75v2l-20.8 12.1z"
        fill="#33C8DA"
      />
      <path
        d="M352.95 284.585v-161.7l-80.05-26.7-80 118.95-159.8-91.9v161.6l159.8 92.4 39.95-23.1v-138.4l56.9-33.3v135.1l3.15 1.8 60.05-34.75zM342 138.985v137.9l-39.5 22.8v-137.8l39.5-22.9zm-140.6 81.5l19.5-11.3v137.5l-19.5 11.25v-137.45zm-17.3 0v135.95l-140.3-79.2.05-138.2 140.25 81.45zm20.6-15.95l67.95-98.55 69.35 19.35-137.3 79.2zm-119.5-20.1l-3.45 2v79.65l59.75 34.6 3.45-2v-81.15l-59.75-33.1zm46.8 100.3l-42.65-24.3v-59.2l42.65 24.7v58.8z"
        fill="#604F44"
      />
      <path
        d="M192.9 220.484v-5.35l-159.8-91.9v161.6l159.8 92.4v-156.75zm-152.2-83.3l3.15 1.85-.05 138.2-3.1 1.95v-142zm100.8 82.45l3.45-2.1v81.15l-3.45 2v-81.05zm-9.5 65.1l-42.65-24.3v-59.2l42.65 24.7v58.8zm91.85-77.25l-2.95 1.7v137.5l-19.5 11.25v3.25l22.45-12.8v-140.9zm69.05-26.9l-3.15 1.85v135.1l3.15 1.8v-138.75zm52.5-43.6l-3.4 2v137.9l-39.5 22.8.05 3.7 42.85-24.55v-141.85zm0-9.75l-3.4-1.9-137.3 79.2-3.3 5.7 144-83z"
        fill="#000"
        fillOpacity={0.149}
      />
      <path
        d="M192.9 220.484v-5.35l-159.8-91.9v161.6l159.8 92.4v-156.75zm-152.2-83.3l3.15 1.85 140.25 81.45v140.7l-140.3-80.2-3.1-1.8v-142zm100.8 163.5v-81.05l-59.75-33.2v79.65l59.75 34.6zm-9.5-15.95l-42.65-24.3v-59.2l42.65 24.7v58.8zm91.85-77.25l-2.95 1.7v137.5l2.95 1.7v-140.9zm69.05-26.9l-3.15 1.85v135.1l3.15 1.8v-138.75zm52.5-43.6l-3.4 2v137.9l3.4 1.95v-141.85z"
        fill="#000"
        fillOpacity={0.149}
      />
      <path
        d="M283.45 226.985c0-6.6-2.033-11.067-6.1-13.4a8.066 8.066 0 00-1.9-.85 10.72 10.72 0 00-1.55-1.1c-4.033-2.333-8.917-1.867-14.65 1.4-5.733 3.333-10.617 8.5-14.65 15.5-4.033 7-6.05 13.817-6.05 20.45 0 6.633 2.017 11.117 6.05 13.45.6.367 1.233.667 1.9.9.467.367.983.717 1.55 1.05 4.033 2.333 8.917 1.833 14.65-1.5 5.733-3.3 10.617-8.45 14.65-15.45 4.067-7 6.1-13.817 6.1-20.45zm-31.7 5.65c3-5.267 6.65-9.15 10.95-11.65 3.667-2.067 6.867-2.633 9.6-1.7.067.033.117.05.15.05.467.4.883.85 1.25 1.35 1.367 1.867 2.05 4.45 2.05 7.75 0 4.967-1.533 10.083-4.6 15.35-3.033 5.233-6.7 9.1-11 11.6-3.767 2.2-7.033 2.75-9.8 1.65a5.601 5.601 0 01-1.1-1.25c-1.367-1.9-2.05-4.517-2.05-7.85 0-4.967 1.517-10.067 4.55-15.3zm-119.75-3.5v-3.2l-42.65-24.7v59.2l42.65 24.3v-55.6zm-17-9.5l14.45 8.1v48.4l-14.45-7.9v-48.6zm-20.25-11.95l14.5 8.6-.05 48.75-14.45-7.9v-49.45zm195-25.25l-5.4 3.15v78.25l-1.6-.9-29.65 16.95v13.05l2.9 1.65 28.35-16.25v36.05l-51.5 29.9v6l56.9-32.6v-135.25zm-30.25 103.9v-2.35l20.8-11.75v2l-20.8 12.1z"
        fill="#EC5C63"
      />
      <path
        d="M282.65 275.584v-4.7l-2.35 1.35v2l-20.8 12.1v2.6l23.15-13.35zm-8.95-54.9c1.367 1.867 2.05 4.45 2.05 7.75 0 4.967-1.533 10.083-4.6 15.35-3.033 5.233-6.7 9.1-11 11.6-3.767 2.2-7.033 2.75-9.8 1.65l.2.2c.367.3.767.567 1.2.8 3 1.767 6.65 1.4 10.95-1.1 4.3-2.5 7.967-6.367 11-11.6 3.067-5.267 4.6-10.383 4.6-15.35 0-4.7-1.383-7.95-4.15-9.75-.167-.1-.317-.2-.45-.3a8.7 8.7 0 00-1.25-.6c.467.4.883.85 1.25 1.35zm1.75-7.95a10.72 10.72 0 00-1.55-1.1c-4.033-2.333-8.917-1.867-14.65 1.4-5.733 3.333-10.617 8.5-14.65 15.5-4.033 7-6.05 13.817-6.05 20.45 0 6.633 2.017 11.117 6.05 13.45.6.367 1.233.667 1.9.9-3-2.533-4.5-6.667-4.5-12.4 0-6.633 2.017-13.45 6.05-20.45 4.033-7 8.917-12.167 14.65-15.5 4.867-2.767 9.117-3.517 12.75-2.25zM132 229.134v-3.2l-42.65-24.7v59.2l42.65 24.3v-55.6zm-17-9.5l14.45 8.1v48.4l-14.45-7.9-2.75 1.45h-.05v-51.6l2.8 1.55zm-22.55-13.35l16.8 10-.05 48.75-14.45-7.9-2.35 1.35.05-52.2zm194.2-22.05l-2.3 1.35v78.25l-1.6-.9-29.65 16.95v13.05l2.9 1.65v-12.95l28.35-16.25 1.35-.8v12.95l-1.35.8v36.05l-51.5 29.9v2.45l53.85-31-.05-131.5z"
        fill="#000"
        fillOpacity={0.149}
      />
      <path
        d="M282.65 275.584v-4.7l-2.35 1.35v2l2.35 1.35zm-26.65 6.05l-2.9-1.75v13.05l2.9 1.65v-12.95zm-124-52.5v-3.2l-42.65-24.7v59.2l42.65 24.3v-55.6zm-17-9.5l14.45 8.1v51.55l-17.2-9.6h-.05v-51.6l2.8 1.55zm-22.55-13.35l16.8 10-.05 51.7-16.8-9.35.05-52.35zm194.2-22.05l-2.3 1.35v78.25l1.35.75v12.95l-1.35.8v36.05l2.35 1.35-.05-131.5z"
        fill="#000"
        fillOpacity={0.149}
      />
      <path
        d="M380.9 139.834l2.4-4.9-191.85-98L102.6.834 1.95 146.434l183.35 105.4 97.7-139.65 97.9 27.65z"
        fill="#EC5C63"
      />
      <path
        d="M380.9 139.834l2.4-4.9-101.8-30.25-103.4-60L102.6.834 1.95 146.434l183.35 105.4 97.7-139.65 97.9 27.65z"
        fill="#000"
        fillOpacity={0.149}
      />
      <path
        d="M281.5 104.684l-103.4-60L102.6.834 1.95 146.434l178.95 102.85 100.6-144.6z"
        fill="#000"
        fillOpacity={0.149}
      />
    </svg>
  );
}

export default Cabane;
