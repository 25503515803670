import React, { useState, useContext } from "react";
import classes from "./ConcertableSix.module.css";
// MUI
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { TextField, Button } from "@material-ui/core";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
// Utils
import { uploadAnswer } from "../../../../utils/TravauxUtils";
// SVG
import GrandeAire from "../../../../svgComponents/base/grandeAire";
import Balancoire from "../../../../svgComponents/options/balancoire";
import JeuxGrimpe from "../../../../svgComponents/options/jeuxGrimpe";
import Toboggan from "../../../../svgComponents/options/toboggan";
import Tourniquet from "../../../../svgComponents/options/tourniquet";
import Trampoline from "../../../../svgComponents/options/trampoline";
import IconAdd from "../../../../svgComponents/options/iconAdd";
// ui
import MapButton from "../../../UI/Buttons/mapButton/mapButton";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ConcertableSix = ({ card, dispatch, width, currentUser, show }) => {
  const classesMUI = useStyles();

  const [choix1, setChoix1] = useState({ svg: <IconAdd x="80" y="100" /> });
  const [choix2, setChoix2] = useState({ svg: <IconAdd x="70" y="110" /> });
  const [choix3, setChoix3] = useState({ svg: <IconAdd x="70" y="110" /> });
  const [comment, setComment] = useState("");

  let options = [
    {
      value: "Toboggan",
      svg: <Toboggan x="-20" y="-50" />,
      label: "Glisser",
    },
    {
      value: "Balancoire",
      svg: <Balancoire x="-35" y="-130" />,
      label: "Se balancer",
    },
    {
      value: "Tourniquet",
      svg: <Tourniquet />,
      label: "Tourner",
    },
    {
      value: "JeuxGrimpe",
      svg: <JeuxGrimpe x="-55" y="-100" />,
      label: "Grimper",
    },
    {
      value: "Trampoline",
      svg: <Trampoline x="-50" y="35" />,
      label: "Sauter",
    },
    // {
    //   value: "CubeIdee",
    //   svg: <CubeIdee />,
    //   label: "J’ai une autre idée",
    // },
  ];

  const handleSubmit = () => {
    let answers = {
      choix1: choix1.value,
      choix2: choix2.value,
      choix3: choix3.value,
    };
    answers["comment"] = comment;
    // console.log(answers);
    uploadAnswer(currentUser.uid, card.id, answers, dispatch);
  };

  // plan button
  const plan = (
    <div style={{ marginTop: "-1rem" }}>
      <MapButton plan={card.plan}>Voir le plan</MapButton>
    </div>
  );

  // Header =============================================================================================
  const header = (
    <header className={classes.CardHeader}>
      <div className={classes.CardTitle}>
        <h4>
          <strong>{card.titleStrong}</strong> {card.titleWeak}
        </h4>
      </div>
      {plan}
    </header>
  );

  // Description  =============================================================================================
  const description = (
    <div className={classes.Description}>
      <p>{card.description}</p>
    </div>
  );

  // Question  =============================================================================================
  // Question  =============================================================================================
  const question = (
    <div className={classes.Question}>
      <p>
        Choisissez en appuyant sur{" "}
        <AddCircleOutlineRoundedIcon
          style={{ fontSize: "1.3rem", marginBottom: "-.4rem" }}
        />
        .
      </p>
    </div>
  );

  // Button Valider =========================================================================================
  const validerButton = (
    <div className={classes.ValiderButton}>
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        style={{ width: "7em", color: "white", fontWeight: "bold" }}
        onClick={handleSubmit}
        disabled={choix1 === "" || choix2 === "" || choix3 === ""}
      >
        Valider
      </Button>
    </div>
  );

  // Bouton Retour ===========================================================
  const revenirButton = (
    <div className={classes.RevenirButton}>
      <Button
        variant="contained"
        size="medium"
        style={{
          width: "7em",
          color: "#333333",
          fontWeight: "bold",
          backgroundColor: "#E0E0E0",
        }}
        onClick={() => dispatch({ type: "SET_HIDECARD", show: false })}
      >
        Revenir
      </Button>
    </div>
  );

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classesMUI.modal}
        open={show}
        onClose={() => dispatch({ type: "SET_HIDECARD", show: false })}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <div className={classes.ModalContent}>
            {header}
            {question}
            <div
              style={{ marginTop: "-1rem" }}
              className={classes.SvgContainer}
            >
              <GrandeAire
                width="100%"
                height="auto"
                choix1={choix1.svg}
                choix2={choix2.svg}
                choix3={choix3.svg}
                setChoix1={setChoix1}
                setChoix2={setChoix2}
                setChoix3={setChoix3}
                options={options}
              />
            </div>
            {description}
            <div className={classes.Buttons}>
              {revenirButton}
              {validerButton}
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ConcertableSix;
